const phrases = {
  accessibility: {
    newWindow: '(új ablakban nyílik meg)',
  },
  articles: {
    loadMore: 'Továbbiak betöltése',
    title: 'Cikkek',
    url: 'cikkek',
  },
  banner: {
    ukraine: 'Humanitárius segítségnyújtás Ukrajnának',
  },
  calendar: {
    loadMore: 'További események',
    title: 'Események',
    url: 'esemenyek',
  },
  churchName: 'Székesfehérvári Baptista Gyülekezet',
  cookie: {
    more: 'Bővebben',
    text: 'Oldalunkon cookie-kat használunk.',
  },
  footer: {
    title: 'Gyülekezet elérhetősége',
    address: 'Cím: 8000, Székesfehérvár Széchenyi út 26.',
    pastor: 'Lelkipásztor: Révész Lajos',
    accountNumber: 'Számlaszám: Unicredit Bank 10918001-00000062-18340002',
    taxNumber: 'Adószám: 18503649-1-07',
    email: 'baptistafehervar@gmail.com',
  },
  header: {
    aboutUs: 'Rólunk',
    articles: 'Hírek',
    events: 'Események',
    groups: 'Szolgálatok',
    liveSermon: 'Élő közvetítés',
    persons: 'Vezetőség',
    sermons: 'Igehirdetések',
    support: 'Támogató leszek',
  },
  live: {
    nextService: 'Következő alkalmunk',
    days: 'nap',
    hours: 'óra',
    minutes: 'perc',
    seconds: 'másodperc',
  },
  main: {
    aboutUs: {
      title: 'Rólunk',
    },
    articles: {
      showMore: 'További cikkek',
      title: 'Hírek, cikkek',
    },
    building: {
      building: 'Kivitelezés',
      completed: 'Elkészült',
      idea: 'Ötlet',
      inProgress: 'Folyamatban van',
      movingIn: 'Használatba vétel',
      notStarted: 'Még nem kezdődött el',
      painting: 'Belső munkálatok',
      planning: 'Tervezés',
      support: 'Támogasd az építkezést!',
      title: 'Imaház bővítése',
    },
    calendar: {
      showMore: 'További események',
      title: 'Események',
    },
    groups: {
      title: 'Szolgálatok',
    },
    map: {
      address: '8000 Székesfehérvár Széchenyi utca 26.',
      clickToShow: 'Kattints a megjelenítéshez',
      iframeTitle: 'térkép',
      mailingList: {
        description: 'Elindult gyülekezetünk hírlevele, melyben tájékoztatást adunk aktuális híreinkről, eseményeinkről.',
        subscribe: 'Iratkozz fel te is!',
        subscribeButton: 'Feliratkozom',
      },
      sermontimes: 'Istentisztelet vasárnap reggel 10 órakor',
      title: 'Csatlakozz hozzánk!',
    },
    persons: {
      title: 'Vezetőség',
    },
    sermons: {
      showMore: 'összes megtekintése',
      title: 'Legfrissebb igehirdetések',
    },
    title: {
      down: 'legörgetés',
      lastSermon: 'A legfrissebb igehirdetés',
      listen: 'meghallgatom',
      sermons: 'igehirdetések',
      szepcel: 'Szépcél építési projekt',
      watch: 'megnézem',
    },
  },
  meta: {
    description: 'Igehirdetések, Élő közvetítés, Cikkek, Események, Szolgálataink, Hitvallásunk.',
    sermonsDescription: 'Korábbi igehirdetések keresése és meghallgatása az elmúlt évekből. Videó- és hangfelvételek, illetve jegyzetek.',
  },
  notFound: {
    title: 'Az oldal nem található',
    text: '404 - A keresett oldal nem található',
  },
  page: {
    days: {
      MONDAY: 'Hétfő',
      TUESDAY: 'Kedd',
      WEDNESDAY: 'Szerda',
      THURSDAY: 'Csütörtök',
      FRIDAY: 'Péntek',
      SATURDAY: 'Szombat',
      SUNDAY: 'Vasárnap',
    },
    events: 'Alkalmak:',
    facebookPage: 'Facebook oldal',
    moreInformation: 'Bővebb információ:',
    photos: 'Képek',
    sermonsTitle: 'Hanganyagok, bizonyságok',
    subpages: 'Csoportok',
  },
  sermons: {
    download: 'letöltés',
    loadMore: 'Korábbiak betöltése',
    play: 'lejátszás',
    search: 'Keresés...',
    title: 'Igehirdetések',
    url: 'igehirdetesek',
  },
  szepcel: {
    title: 'Szépcél',
  },
};

export default phrases;
